@import 'vars';
@import 'settings';
@font-face {
  font-family: 'fontello';
  src: url('../Fonts/fontello.eot?3627878');
  src: url('../Fonts/fontello.eot?3627878#iefix') format('embedded-opentype'),
       url('../Fonts/fontello.woff2?3627878') format('woff2'),
       url('../Fonts/fontello.woff?3627878') format('woff'),
       url('../Fonts/fontello.ttf?3627878') format('truetype'),
       url('../Fonts/fontello.svg?3627878#fontello') format('svg');
  font-weight: normal;
  font-style: normal;
}
/* Chrome hack: SVG is rendered more smooth in Windozze. 100% magic, uncomment if you need it. */
/* Note, that will break hinting! In other OS-es font will be not as sharp as it could be */
/*
@media screen and (-webkit-min-device-pixel-ratio:0) {
  @font-face {
    font-family: 'fontello';
    src: url('../font/fontello.svg?3627878#fontello') format('svg');
  }
}
*/

 [class^="icon-"]:before, [class*=" icon-"]:before {
  font-family: "fontello";
  font-style: normal;
  font-weight: normal;
  speak: none;

  display: inline-block;
  text-decoration: inherit;
  width: 1em;
  margin-right: .2em;
  text-align: center;
  /* opacity: .8; */

  /* For safety - reset parent styles, that can break glyph codes*/
  font-variant: normal;
  text-transform: none;

  /* fix buttons height, for twitter bootstrap */
  line-height: 1em;

  /* Animation center compensation - margins should be symmetric */
  /* remove if not needed */
  margin-left: .2em;

  /* you can be more comfortable with increased icons size */
  /* font-size: 120%; */

  /* Font smoothing. That was taken from TWBS */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  /* Uncomment for 3D effect */
  /* text-shadow: 1px 1px 1px rgba(127, 127, 127, 0.3); */
}

.icon-search:before { content: '\e824'; }
.icon-mail-alt:before { content: '\e826'; }
.icon-users:before { content: '\e831'; }
.icon-user-secret:before { content: '\e835'; }
.icon-videocam:before { content: '\e837'; }
.icon-picture:before { content: '\e838'; }
.icon-camera:before { content: '\e839'; }
.icon-ok:before { content: '\e83e'; }
.icon-cancel:before { content: '\e842'; }
.icon-cancel-circled:before { content: '\e843'; }
.icon-plus:before { content: '\e845'; }
.icon-minus:before { content: '\e849'; }
.icon-help:before { content: '\e84d'; }
.icon-home:before { content: '\e851'; }
.icon-eye:before { content: '\e85b'; }
.icon-tag:before { content: '\e85d'; }
.icon-tags:before { content: '\e85e'; }
.icon-flag:before { content: '\e861'; }
.icon-thumbs-up-alt:before { content: '\e866'; }
.icon-thumbs-down-alt:before { content: '\e867'; }
.icon-quote-left:before { content: '\e86f'; }
.icon-quote-right:before { content: '\e870'; }
.icon-share:before { content: '\e874'; }
.icon-pencil:before { content: '\e876'; }
.icon-print:before { content: '\e879'; }
.icon-comment:before { content: '\e87d'; }
.icon-chat:before { content: '\e87e'; }
.icon-bell-alt:before { content: '\e882'; }
.icon-bell-off:before { content: '\e883'; }
.icon-doc:before { content: '\e88d'; }
.icon-docs:before { content: '\e88e'; }
.icon-doc-text:before { content: '\e88f'; }
.icon-doc-inv:before { content: '\e890'; }
.icon-doc-text-inv:before { content: '\e891'; }
.icon-file-pdf:before { content: '\e892'; }
.icon-folder:before { content: '\e89b'; }
.icon-folder-open:before { content: '\e89c'; }
.icon-folder-empty:before { content: '\e89d'; }
.icon-folder-open-empty:before { content: '\e89e'; }
.icon-fax:before { content: '\e8a4'; }
.icon-menu:before { content: '\e8a5'; }
.icon-cog:before { content: '\e8a6'; }
.icon-wrench:before { content: '\e8a8'; }
.icon-calendar:before { content: '\e8ad'; }
.icon-calendar-empty:before { content: '\e8ae'; }
.icon-login:before { content: '\e8af'; }
.icon-lightbulb:before { content: '\e8b8'; }
.icon-left-dir:before { content: '\e8c8'; }
.icon-right-dir:before { content: '\e8c9'; }
.icon-angle-left:before { content: '\e8ce'; }
.icon-angle-right:before { content: '\e8cf'; }
.icon-angle-up:before { content: '\e8d0'; }
.icon-angle-down:before { content: '\e8d1'; }
.icon-angle-circled-left:before { content: '\e8d2'; }
.icon-angle-circled-right:before { content: '\e8d3'; }
.icon-angle-double-left:before { content: '\e8d6'; }
.icon-angle-double-right:before { content: '\e8d7'; }
.icon-left:before { content: '\e8db'; }
.icon-right:before { content: '\e8dc'; }
.icon-right-hand:before { content: '\e8e2'; }
.icon-left-hand:before { content: '\e8e3'; }
.icon-award:before { content: '\e905'; }
.icon-globe:before { content: '\e90b'; }
.icon-umbrella:before { content: '\e910'; }
.icon-paper-plane:before { content: '\e913'; }
.icon-paper-plane-empty:before { content: '\e914'; }
.icon-paste:before { content: '\e930'; }
.icon-briefcase:before { content: '\e931'; }
.icon-suitcase:before { content: '\e932'; }
.icon-road:before { content: '\e936'; }
.icon-book:before { content: '\e93a'; }
.icon-circle:before { content: '\e941'; }
.icon-circle-empty:before { content: '\e942'; }
.icon-gift:before { content: '\e947'; }
.icon-magnet:before { content: '\e949'; }
.icon-chart-area-1:before { content: '\e94b'; }
.icon-chart-pie-4:before { content: '\e94c'; }
.icon-ticket:before { content: '\e94e'; }
.icon-megaphone:before { content: '\e951'; }
.icon-rocket:before { content: '\e955'; }
.icon-beaker:before { content: '\e95a'; }
.icon-magic:before { content: '\e95b'; }
.icon-cab:before { content: '\e95c'; }
.icon-money:before { content: '\e965'; }
.icon-hammer:before { content: '\e97a'; }
.icon-gauge:before { content: '\e97b'; }
.icon-coffee:before { content: '\e97e'; }
.icon-heartbeat:before { content: '\e983'; }
.icon-ambulance:before { content: '\e984'; }
.icon-hospital:before { content: '\e988'; }
.icon-building:before { content: '\e989'; }
.icon-building-filled:before { content: '\e98a'; }
.icon-bank:before { content: '\e98b'; }
.icon-puzzle:before { content: '\e992'; }
.icon-shield:before { content: '\e993'; }
.icon-graduation-cap:before { content: '\e998'; }
.icon-tty:before { content: '\e9a6'; }
.icon-binoculars:before { content: '\e9a7'; }
.icon-newspaper-2:before { content: '\e9a9'; }
.icon-calc:before { content: '\e9aa'; }
.icon-diamond-1:before { content: '\e9b0'; }
.icon-facebook:before { content: '\e9d8'; }
.icon-github:before { content: '\e9e0'; }
.icon-linkedin:before { content: '\e9f3'; }
.icon-pinterest-4:before { content: '\e9fb'; }
.icon-twitter:before { content: '\ea17'; }
.icon-user-1:before { content: '\ea33'; }
.icon-users-1:before { content: '\ea34'; }
.icon-cancel-1:before { content: '\ea3c'; }
.icon-cancel-circled-1:before { content: '\ea3d'; }
.icon-attach-1:before { content: '\ea4c'; }
.icon-feather:before { content: '\ea60'; }
.icon-address:before { content: '\ea6a'; }
.icon-location-1:before { content: '\ea6b'; }
.icon-book-1:before { content: '\ea78'; }
.icon-folder-1:before { content: '\ea79'; }
.icon-rss-1:before { content: '\ea7c'; }
.icon-clock-1:before { content: '\ea8b'; }
.icon-hourglass:before { content: '\ea8c'; }
.icon-left-open-1:before { content: '\ea9d'; }
.icon-right-open-1:before { content: '\ea9e'; }
.icon-globe-1:before { content: '\eada'; }
.icon-paper-plane-1:before { content: '\eae0'; }
.icon-lifebuoy-1:before { content: '\eae2'; }
.icon-graduation-cap-1:before { content: '\eaf2'; }
.icon-clipboard:before { content: '\eafa'; }
.icon-rocket-1:before { content: '\eb06'; }
.icon-gauge-1:before { content: '\eb07'; }
.icon-traffic-cone:before { content: '\eb08'; }
.icon-vimeo:before { content: '\eb18'; }
.icon-gplus-1:before { content: '\eb1f'; }
.icon-dribbble-1:before { content: '\eb27'; }
.icon-instagram:before { content: '\eb32'; }
.icon-dropbox-1:before { content: '\eb33'; }
.icon-evernote:before { content: '\eb34'; }
.icon-skype-1:before { content: '\eb36'; }
.icon-behance-1:before { content: '\eb3e'; }
.icon-heart-filled:before { content: '\eb4b'; }
.icon-star-filled:before { content: '\eb4d'; }
.icon-lock-filled:before { content: '\eb7c'; }
.icon-lock-open-filled:before { content: '\eb7e'; }
.icon-flag-filled:before { content: '\eb87'; }
.icon-globe-alt:before { content: '\ec1a'; }
.icon-mail-3:before { content: '\ec7a'; }
.icon-calendar-inv:before { content: '\ecc1'; }
.icon-home-4:before { content: '\ed21'; }
.icon-eye-4:before { content: '\ed24'; }
.icon-attention-3:before { content: '\ed30'; }
.icon-info-4:before { content: '\ed31'; }
.icon-question:before { content: '\ed32'; }
.icon-article-alt-1:before { content: '\ed37'; }
.icon-wrench-3:before { content: '\ed39'; }
.icon-clock-4:before { content: '\ed42'; }
.icon-award-2:before { content: '\ed52'; }
.icon-book-3:before { content: '\ed59'; }
.icon-chart-pie-3:before { content: '\ed5e'; }
.icon-dollar-1:before { content: '\ed5f'; }
.icon-money-1:before { content: '\ed64'; }
.icon-user-male:before { content: '\eda3'; }
.icon-users-3:before { content: '\eda5'; }
.icon-ok-4:before { content: '\edae'; }
.icon-ok-circled-1:before { content: '\edaf'; }
.icon-cancel-5:before { content: '\edb0'; }
.icon-cancel-circled-3:before { content: '\edb1'; }
.icon-link-4:before { content: '\edb8'; }
.icon-lock-5:before { content: '\edba'; }
.icon-lock-alt:before { content: '\edbb'; }
.icon-lock-open-5:before { content: '\edbc'; }
.icon-lock-open-alt-1:before { content: '\edbd'; }
.icon-upload-cloud-4:before { content: '\edc2'; }
.icon-bell-4:before { content: '\edca'; }
.icon-left-open-3:before { content: '\ede1'; }
.icon-right-open-3:before { content: '\ede2'; }
.icon-down-5:before { content: '\ede4'; }
.icon-left-4:before { content: '\ede5'; }
.icon-right-4:before { content: '\ede6'; }
.icon-up-5:before { content: '\ede7'; }
.icon-globe-4:before { content: '\ee00'; }
.icon-globe-inv:before { content: '\ee01'; }
.icon-art-gallery:before { content: '\ee36'; }
.icon-baseball:before { content: '\ee38'; }
.icon-cinema:before { content: '\ee41'; }
.icon-fast-food:before { content: '\ee47'; }
.icon-garden:before { content: '\ee4c'; }
.icon-industrial-building:before { content: '\ee53'; }
.icon-minefield:before { content: '\ee57'; }
.icon-police:before { content: '\ee5c'; }
.icon-post:before { content: '\ee5d'; }
.icon-soccer:before { content: '\ee68'; }
.icon-theatre:before { content: '\ee6b'; }
.icon-town-hall:before { content: '\ee6d'; }
.icon-tree-3:before { content: '\ee70'; }
.icon-forrst:before { content: '\ee89'; }
.icon-digg-1:before { content: '\ee8a'; }
.icon-appstore:before { content: '\ee8f'; }
.icon-flickr-3:before { content: '\ee94'; }
.icon-youtube-2:before { content: '\eea2'; }
.icon-blogger-1:before { content: '\eeed'; }
.icon-deviantart-1:before { content: '\eeef'; }
.icon-lastfm-3:before { content: '\eef1'; }
.icon-wordpress-2:before { content: '\eef6'; }
.icon-folder-5:before { content: '\ef63'; }
.icon-folder-close:before { content: '\ef65'; }
.icon-folder-open-2:before { content: '\ef66'; }
.icon-phone-3:before { content: '\ef68'; }
.icon-cogs:before { content: '\ef6c'; }
.icon-calendar-7:before { content: '\ef71'; }
.icon-lightbulb-2:before { content: '\ef7c'; }
.icon-arrows-cw-2:before { content: '\ef99'; }
.icon-shuffle-4:before { content: '\ef9a'; }
.icon-desktop-2:before { content: '\efae'; }
.icon-laptop-2:before { content: '\efb0'; }
.icon-leaf-3:before { content: '\efbb'; }
.icon-magnet-2:before { content: '\efd7'; }
.icon-megaphone-2:before { content: '\efdb'; }
.icon-key-4:before { content: '\efde'; }
.icon-asl:before { content: '\efe7'; }
.icon-stumbleupon-3:before { content: '\f00d'; }
.icon-ok-6:before { content: '\f050'; }
.icon-balance-scale:before { content: '\f24e'; }
.icon-handshake-o:before { content: '\f2b5'; }
.icon-envelope-open:before { content: '\f2b6'; }

@import 'fontello-hovers';
