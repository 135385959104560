/* ICONS HOVERS */
.icon-facebook {
	&.icon-hover, &:hover {
		background-color: #4767a0;
	}
}
.icon-github {
	&.icon-hover, &:hover {
		background-color: #7192a3;
	}
}
.icon-linkedin {
	&.icon-hover, &:hover {
		background-color: #267bb9;
	}
}
.icon-pinterest-4 {
	&.icon-hover, &:hover {
		background-color: #c41818;
	}
}
.icon-twitter {
	&.icon-hover, &:hover {
		background-color: #47c0d8;
	}
}
.icon-rss-1 {
	&.icon-hover, &:hover {
		background-color: #ff9a00;
	}
}
.icon-vimeo {
	&.icon-hover, &:hover {
		background-color: #54869a;
	}
}
.icon-gplus-1 {
	&.icon-hover, &:hover {
		background-color: #c53c07;
	}
}
.icon-dribbble-1 {
	&.icon-hover, &:hover {
		background-color: #e85692;
	}
}
.icon-instagram {
	&.icon-hover, &:hover {
		background-color: #4f7ea4;
	}
}
.icon-dropbox-1 {
	&.icon-hover, &:hover {
		background-color: #59a1ea;
	}
}
.icon-evernote {
	&.icon-hover, &:hover {
		background-color: #71b306;
	}
}
.icon-skype-1 {
	&.icon-hover, &:hover {
		background-color: #11afe7;
	}
}
.icon-behance-1 {
	&.icon-hover, &:hover {
		background-color: #00a0ff;
	}
}
.icon-post {
	&.icon-hover, &:hover {
		background-color: #63a600;
	}
}
.icon-forrst {
	&.icon-hover, &:hover {
		background-color: #618366;
	}
}
.icon-digg-1 {
	&.icon-hover, &:hover {
		background-color: #2080c8;
	}
}
.icon-appstore {
	&.icon-hover, &:hover {
		background-color: #606060;
	}
}
.icon-flickr-3 {
	&.icon-hover, &:hover {
		background-color: #ea2487;
	}
}
.icon-youtube-2 {
	&.icon-hover, &:hover {
		background-color: #b92c1d;
	}
}
.icon-blogger-1 {
	&.icon-hover, &:hover {
		background-color: #f59022;
	}
}
.icon-deviantart-1 {
	&.icon-hover, &:hover {
		background-color: #5f7162;
	}
}
.icon-lastfm-3 {
	&.icon-hover, &:hover {
		background-color: #c10000;
	}
}
.icon-wordpress-2 {
	&.icon-hover, &:hover {
		background-color: #2f749f;
	}
}
.icon-stumbleupon-3 {
	&.icon-hover, &:hover {
		background-color: #ed4713;
	}
}
.icon-print,
.icon-file-pdf {
	&.icon-hover, &:hover {
		background-color: $main-theme-color;
	}
}